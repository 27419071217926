.ManageStakeholderContainers {
  justify-content: center;
  align-items: center;
  top: 60px;
  bottom: 0;
  right: 0px;
  left: 225px;
  overflow-y: auto;
  position: fixed !important;
  /* padding: 20px 30px 30px 30px; */
}

.ManageStakeholderHeader {
  width: '100%';
  /* background-color: tomato; */
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  /* box-shadow: 5px 5px 5px lightgrey; */
}

.ManageStakeholderHeaderButton {
  position: absolute;
  left: 10px;
  align-items: center;
  justify-content: center;
  /* background-color: tan; */
}

.link {
  color: #fff;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.link:visited {
  color: #fff;
}
.link:hover {
  color: #fff;
}

.ManageStakeholderHeaderTitle {
  width: '50%';
  height: 50px;
  display: flex;
  /* background-color: aqua; */
  align-items: center;
  justify-content: center;
}
.HeaderText {
  margin-top: 10px;
  height: 40px;
  padding: 0px 10px 0px 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 20;
  font-weight: 700;
  color: #fff;
  background-color: tomato;
  border-radius: 10px;
  box-shadow: 5px 5px 5px lightgrey;
}

.AddStakeholderContainer {
  margin-top: 10px;
  width: '100%';
  /* background-color: tomato; */
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 10px;
  /* box-shadow: 5px 5px 5px lightgrey; */
}

.AddStakeholder {
  /* background-color: tan; */
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  cursor: pointer;
}

.EditStakeholder {
  /* background-color: tan; */
  width: 100%;
  display: flex;
  /* flex-direction: row;
    justify-content: space-evenly; */
  align-items: center;
  cursor: pointer;
  margin: 0 auto;
}

.ManageStakeholdersTable {
  width: '80%';
  height: '70%';
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 60px;
  margin-right: 60px;
  box-shadow: 5px 5px 5px lightgrey;
  border-radius: 15px;
}

.DesignationInputArea {
  width: '100%';
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
/* Theming */
.btn-primary {
  background-color: tomato;
  border-radius: 50px;
  border-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.btn-primary:disabled {
  background: tomato;
  border-color: transparent;
}
.btn-primary:hover {
  background: rgb(241, 119, 97);
  border-color: transparent;
}
.btn-primary:focus {
  background: tomato;
  border-color: transparent;
}

.dynamicInputFields {
  margin-top: 10px;
  width: '100%';
  /* background-color: red; */
  /* height: 40px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.swal-button {
  padding: 7px 19px;
  border-radius: 2px;
  background-color: tomato;
  font-size: 15px;
  border: 1px solid tomato;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
}
