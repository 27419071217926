.container-main {
  height: 500px;
  border: 2px solid green;
  background-color: #f5f5f5;
}

.nav-design {
  position: relative;
  top: 50px;
  border: 5px solid yellow;
  background-color: white;
}

@media only screen and (max-width: 600px) {
  .tabhandler {
    flex-direction: column;
    overflow: scroll;
  }
}
