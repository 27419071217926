.Tab2Container {
  width: 100%;
  /* height: 100vh; */
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  overflow: auto;
}
.Tab2EditButtonContainer {
  margin-top: 10px;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  /* background-color: tomato; */
  margin-bottom: 10px;
  border-radius: 10px;
  /* box-shadow: 5px 5px 5px lightgrey; */
}
.Tab2EditButton {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.Tab2EditButtonText {
  height: 50px;
  padding: 0px 10px 0px 10px;
  font-size: 20;
  font-weight: 700;
  color: #fff;
  cursor: pointer;
  background-color: tomato;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  min-width: 200px;
  box-shadow: 5px 5px 5px lightgrey;
  border-radius: 10px;
}
.Tab2Table {
  margin-top: 10px;
  width: 100%;
  overflow: scroll;
  display: flex;
  /* align-items: center; */
  justify-content: center;

  flex-direction: column;
  margin-bottom: 10px;
  border-radius: 10px;
  box-shadow: 5px 5px 5px lightgrey;
}

.datePicker {
  cursor: pointer;
  width: 100%;
  border: #fff;
}

.tdStyle {
  white-space: nowrap;
  overflow: auto;
}
