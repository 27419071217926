.AddEditUserContainers {
  justify-content: center;
  align-items: center;
  top: 60px;
  bottom: 0;
  right: 0px;
  left: 225px;
  /* overflow-y: auto; */
  position: absolute !important;
  /* padding: 20px 30px 30px 30px; */
}

.AddEditUserHeader {
  width: '100%';
  /* background-color: tomato; */
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 10px;
}

.AddEditUserHeaderButton {
  position: absolute;
  left: 10px;
  align-items: center;
  justify-content: center;
  /* background-color: tan; */
}
.edit-branch-button {
  position: absolute;
  right: 20px;
  align-items: center;
  justify-content: center;
  /* background-color: tan; */
}
.link {
  color: #fff;
  text-decoration: none;
}
.link:visited {
  color: #fff;
}
.link:hover {
  color: #fff;
}

.AddEditUserHeaderTitle {
  width: '50%';
  height: 50px;
  display: flex;
  /* background-color: aqua; */
  align-items: center;
  justify-content: center;
}

.add-edit-user-tab-header {
  color: tomato;
  font-weight: 600;
  font-size: 18px;
}
