.Tab1Container {
  width: 100%;
  /* height: 100vh; */
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  overflow: auto;
}
.editButtonContainer {
  margin-top: 10px;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  /* background-color: tomato; */
  margin-bottom: 10px;
  border-radius: 10px;
  /* box-shadow: 5px 5px 5px lightgrey; */
}
.editButton {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  /* background-color: red; */
}
.editButtonText {
  height: 50px;
  padding: 0px 10px 0px 10px;
  border-radius: 10px;
  font-size: 20;
  font-weight: 700;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: tomato;
  box-shadow: 5px 5px 5px lightgrey;
}
.Tab1Table {
  margin-top: 10px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 10px;
  box-shadow: 5px 5px 5px lightgrey;
  border-radius: 10px;
}

.Tab1TableElements {
  width: 100%;
}
