.login-container-projects {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #edeff0;
  display: flex;
}
.sahaj-front-projects {
  background: url(/images/hero-image.jpg) no-repeat center;
  background-size: 100%;
  box-shadow: inset 0 0 0 1200px rgb(70 50 50 / 10%);
  width: 65%;
  height: 122%;
  margin-top: 37px;
  /* padding-top: 89px; */
  padding: 0 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.button-project {
  margin-top: 12vh;
  margin-top: 41;
  width: 13.5vw;
  color: white;
  height: 5vh;
  background-color: var(--themeColor);
  border: none;
}

#multiselectContainerReact {
  width: 100%;
  margin-left: 0;
}
